import React, { useState, useEffect, useRef } from 'react';
import { LogoutProps } from './props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faAdjust } from '@fortawesome/free-solid-svg-icons';
import { Client, ResponseType } from '@microsoft/microsoft-graph-client';
import { PublicClientApplication } from '@azure/msal-browser';
import { configuration } from '../../../constants/auth'; // Importa la configuración

interface LogoutBtnProps extends LogoutProps {
  initials: string;
  hasFirstMessageSent: boolean; // Nueva propiedad
  userName: string; // Nuevo prop para el nombre del usuario
  userRole: string; // Nuevo prop para el rol del usuario
}

const msalInstance = new PublicClientApplication(configuration);

const initializeMsal = async () => {
  await msalInstance.initialize();
};

const LogoutBtn: React.FC<LogoutBtnProps> = ({ userToken, logout, initials, hasFirstMessageSent, userName, userRole }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const [profilePhoto, setProfilePhoto] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // Función para alternar el tema
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme); // Guardar en localStorage
  };

  // Al cargar el componente, detectar el tema del sistema o cargar el tema guardado
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') as 'light' | 'dark';
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    const initialTheme = savedTheme || systemTheme;
    setTheme(initialTheme);
    document.documentElement.setAttribute('data-theme', initialTheme);
  }, []);

  // Cierra el menú si se hace clic fuera o se vuelve a pulsar
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.logout-btn') // Ignora clics en el botón
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleButtonClick = () => {
    setIsMenuOpen((prev) => !prev);
  };

  // Obtener la foto de perfil del usuario
  useEffect(() => {
    if (!userToken) return; // Solo se ejecuta si hay token
  
    const getProfilePhoto = async () => {
      try {
        await initializeMsal(); // Inicializa msalInstance
        const account = msalInstance.getAllAccounts()[0];
        if (account) {
          const tokenResponse = await msalInstance.acquireTokenSilent({
            scopes: ['User.Read'],
            account: account,
          });

          const client = Client.init({
            authProvider: (done) => {
              done(null, tokenResponse.accessToken);
            },
          });

          const photoResponse = await client
            .api('/me/photo/$value')
            .responseType(ResponseType.BLOB)
            .get();

          if (photoResponse) {
            const url = URL.createObjectURL(photoResponse);
            setProfilePhoto(url);
          } else {
            throw new Error('Photo not found');
          }
        }
      } catch (error: any) {
        console.error('Error fetching profile photo:', error.message);
        setProfilePhoto(null);
      }
    };

    getProfilePhoto();
  }, [userToken]);

  const resetChat = () => {
    window.location.reload();
  };

  return (
    userToken && (
      <div className="logout-container">
        {hasFirstMessageSent && (
          <button
            className="nuevo-chat-btn"
            onClick={resetChat}
          >
            Nuevo Chat
          </button>
        )}
        <button className="logout-btn" onClick={handleButtonClick}>
          {profilePhoto ? (
            <img src={profilePhoto} alt="Profile" className="profile-photo" />
          ) : (
            <div className="initials">{initials}</div>
          )}
        </button>
        {isMenuOpen && (
          <div className="logout-menu" ref={menuRef}>
            <div className="user-info">
              {profilePhoto ? (
                <img src={profilePhoto} alt="Profile" className="menu-profile-photo" />
              ) : (
                <div className="initials menu-initials">{initials}</div>
              )}
              <div className="user-details">
                <p className="user-name">{userName}</p>
                <p className="user-role">{userRole}</p>
              </div>
            </div>
            <button onClick={toggleTheme}>
              <FontAwesomeIcon icon={faAdjust} /> Cambiar a {theme === 'light' ? 'Modo Oscuro' : 'Modo Claro'}
            </button>
            <button onClick={logout}>
              <FontAwesomeIcon icon={faSignOutAlt} /> Cerrar sesión
            </button>
          </div>
        )}
      </div>
    )
  );
};

export default LogoutBtn;