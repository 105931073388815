import React, { useState, useEffect, useRef } from 'react';
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk';
import ReactPlayer from 'react-player';
import BarChart from '../components/Chat/components/Chart/BarChart';
import Chat from '../components/Chat';
import LogoutBtn from '../components/LogoutBtn';
import '../../css/avatar.css';
import useMsalHook from '../../auth/useMsalHook';

//import GPTCEUImg_footer from '../../assets/images/CEU-UCH_footer-blanco.png';

import { AvatarConfiguration, AvatarResponse } from '../../models/avatar';
import { Message, toMessageFormat } from '../../models/chat';
import { PeerConnection } from '../../models/peerConnection';

import micNormalImg from '../../assets/images/microfono.png';
import micListeningImg from '../../assets/images/microfono-escuchando.png';
import micForbiddenImg from '../../assets/images/microfono-prohibido.png';
import {
  DESARROLLO_MODE,
  VOLUMEN_OFF,
  VOLUMEN_ON,
  avatarType,
  usuario,
} from '../../constants';
import {
  getInitialConfig,
  getGptData,
  getVariableData,
  getAzureVoiceToken,
  uploadFile,
  postOnIceCandidate,
  postCreateSession,
  postSendAnswer,
  postInitStreaming,
} from '../../services/api';
import { AvatarContext } from '../../context/Avatar/AvatarContext';
import { AvatarContextModel } from '../../context/Avatar/props';
import { showToast } from '../../helpers/toast';
import NoData from '../components/NoData';
import { AzureTalk } from '../../models/azure';
import { checkMicPermissions } from '../../helpers/mic';
//import { parse } from '@fortawesome/fontawesome-svg-core';

var loadingIce: boolean = false; // used to avoid multiple request from d-id "/ice"
var isReconnecting: boolean = false;
var isWaitingResponse: boolean = false;
var isMicOpened: boolean = false; // controla si el micro está abierto/escuchando(true) o cerrado/pausado/inexistente(false)
let speechRef: speechsdk.SpeechRecognizer | null = null;

const AvatarApp = () => {
  const { user, userToken, logout } = useMsalHook();

  // useState
  const [displayText, setDisplayText] = useState<string>('Pulsa para hablar');
  const [chatInput, setChatInput] = useState<string>('');
  const [isChatSent, setIsChatSent] = useState<boolean>(false);
  const [hasFirstMessageSent, setHasFirstMessageSent] = useState(false);
  const [isMicEnabled, setIsMicEnabled] = useState<boolean | undefined>(
    undefined
  ); // controla si el micro ha sido creado(true)/pausado(false)/inexistente(undefined)
  const [streamingState, setStreamingState] = useState<string | undefined>(''); // aqui el undefined es solo por tipado, nunca será
  const [prevStreamingState, setPrevStreamingState] = useState<
    string | undefined
  >(''); // aqui el undefined es solo por tipado, nunca será
  const [micImg, setMicImg] = useState<string>(micNormalImg);
  const [showStreaming, setShowStreaming] = useState<boolean>(true); //deberia ser false, para ver el video de vimeo
  const [messages, setMessages] = useState<Message[]>([]);
  const [isBotThinking, setIsBotThinking] = useState<boolean>(false);
  const [initialConfig, setInitialConfig] = useState<
    AvatarConfiguration | undefined
  >(undefined);
  const [errorOnInitialConfig, setErrorOnInitialConfig] = useState<string>('');

  //chart
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [chartData, setChartData] = useState<number[]>([]);
  const [showChart, setShowChart] = useState(false);

  // useRef
  const talkVideoRef = useRef<HTMLVideoElement>(null);
  const peerConnectionRef = useRef<PeerConnection[]>([
    { id: 0, connection: null, active: false },
    { id: 1, connection: null, active: false },
    { id: 2, connection: null, active: false },
    { id: 3, connection: null, active: false },
  ]);
  const userRefIndexConnection = useRef<number>(0);
  let indexConnection = userRefIndexConnection.current;
  const streamIdRef = useRef<string | null>(null);
  const sessionIdRef = useRef<string | null>(null);

  // const
  const streamingMode: boolean = initialConfig?.avatarMode === 'streaming';
  const streamingOnlyMode: boolean =
    initialConfig?.avatarMode === 'streaming_only';
  // const chatOnlyMode: boolean = initialConfig?.avatarMode === 'chat_only';
  const voiceOnlyMode: boolean = initialConfig?.avatarMode === 'chat_voice';
  const voiceSpeechMode: boolean =
    initialConfig?.avatarMode === 'chat_voice_speech';
  const userName = user?.name || usuario || 'Me';
  //console.log('userRole:', userRole);
  const [role, setRole] = useState<string>(user?.role || 'Usuario');

  //TAGS
  const [tags, setTags] = useState<string[]>([]);
  const [tagFrases, setTagFrases] = useState<{ [key: string]: string[] }>({});
  const [suggestions, setSuggestions] = useState<string[]>([]); // Frases sugeridas
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false); // Controlar visibilidad de las sugerencias
  const suggestionsRef = useRef<HTMLDivElement>(null);

  // SUGERENCIAS
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
        setShowSuggestions(false); // Ocultar las sugerencias si se hace clic fuera
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // CARGAR CONFIGURACIÓN INICIAL
  useEffect(() => {
    if (avatarType) {
      (async () => {
        showToast('Cargando configuración inicial...', 'info');
        const initialConfig = await getInitialConfig();
        if (initialConfig) {
          showToast(`Configuración de "${avatarType}" cargada.`, 'success');
          // initialConfig.avatarMode = 'streaming_only';
          setInitialConfig(initialConfig);
        } else {
          setErrorOnInitialConfig(
            `No se pudo cargar o no existe el avatar "${avatarType}".`
          );
          showToast(`No se pudo cargar "${avatarType}".`, 'error');
        }
      })();
    }
  }, []);

  // RESET
  useEffect(() => {
    (async () => {
      if (userToken && user && initialConfig) {
        const initialRes = await getGptData('--reset', userToken, user);

        if (initialRes?.respuesta_corta?.tags) {
          const extractedTags = initialRes.respuesta_corta.tags.map(tag => tag.Tag);
          setTags(extractedTags);
          const extractedTagFrases = initialRes.respuesta_corta.tags.reduce((acc, tag) => {
            acc[tag.Tag] = tag.frases || [];
            return acc;
          }, {} as { [key: string]: string[] });
          setTagFrases(extractedTagFrases);
        }
        // Actualiza el rol con el valor obtenido de initialRes
        if (initialRes?.respuesta_corta?.Rol) {
          setRole(initialRes.respuesta_corta.Rol);
        }

        if (initialRes?.respuesta_corta?.entorno === DESARROLLO_MODE) {
          document.getElementById('parent-compontent')
            ?.classList.add('avatar-component-des');
        }
        if (!streamingMode && !streamingOnlyMode) {
          // ...
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialConfig, userToken, user]);

  // iniciales del usuario si no tiene foto de perfil
  const getInitials = (name: string) => {
    const [firstName, lastName] = name.split(' ');
    const initials = `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`;
    return initials.toUpperCase();
  };

  const userInitials = getInitials(userName);
  //console.log('Iniciales del usuario:', userInitials);

  // FRASE ALEATORIA DE LA SUGERENCIA
  const getRandomPhrase = (frases: string[]): string => {
    if (frases.length === 0) return ''; // Si no hay frases, devuelve una cadena vacía
    const randomIndex = Math.floor(Math.random() * frases.length);
    return frases[randomIndex];
  };

  const initializeOrResumeMic = async (speechRecognizer: any) => {
    if (speechRecognizer) {
      await checkMicPermissions();
      speechRecognizer.startContinuousRecognitionAsync(
        onStartContinuousRecognition(speechRecognizer),
        onStartContinuousRecognitionError
      );
    }
  };

  const pauseMic = () => {
    if (speechRef) {
      isMicOpened = false;
      speechRef.stopContinuousRecognitionAsync();
    }
  };

  const destroyMic = (defaultState: boolean = false) => {
    if (speechRef) {
      isMicOpened = false;
      speechRef.stopContinuousRecognitionAsync();
      speechRef = null;
      if (defaultState) {
        setTimeout(() => {
          setIsMicEnabled(undefined);
        }, 200);
      }
    }
  };

  const changeVisualMode = (mode: string) => {
    const resetStates = () => {
      isReconnecting = false;
      isMicOpened = false;
      setDisplayText('Pulsa para hablar');
      setIsChatSent(false);
      setIsMicEnabled(undefined);
    };
    switch (mode) {
      case 'finish':
        if (!isMicOpened) {
          initializeOrResumeMic(speechRef);
        }
        if (!streamingMode && !streamingOnlyMode) {
          setIsChatSent(false);
        }
        break;
      case 'reset':
        resetStates();
        break;
      case 'failed':
        resetStates();
        setDisplayText(
          'No se pudo conectar con los servicios de streaming. Pulsa para reintentarlo'
        );
        break;
      case 'open-mic':
        if (!isMicOpened) {
          initializeOrResumeMic(speechRef);
        }
        break;
      case 'close-mic':
        if (isMicOpened) {
          pauseMic();
        }
        break;
      default:
        break;
    }
  };

  const onStartContinuousRecognition = (
    speechRecognizer: speechsdk.SpeechRecognizer | null
  ) => {
    speechRef = speechRecognizer;
    isMicOpened = true;
    if (!isReconnecting) {
      setIsMicEnabled(true);
      // !speechRef && setDisplayText('Escuchando...');
      setDisplayText('Escuchando...');
    } else {
      setTimeout(() => {
        console.log('Se inicia el micro en pausa tras reconectar');
        // damos 0,3s para que el nuevo micrófono termine de crearse
        pauseMic();
      }, 300);
    }
    isReconnecting = false;
    console.log('Micrófono activado.');
  };

  const onStartContinuousRecognitionError = (err: string) =>
    console.log('onStartRecognitionError', err);

  const setRecognizerMethods = (
    speechRecognizer: speechsdk.SpeechRecognizer
  ) => {
    speechRecognizer.recognized = (s, e) => {
      // console.log(`ESCUCHANDO...${isMicOpened} ${e.result.text}`);
      if (
        e.result.reason === speechsdk.ResultReason.RecognizedSpeech &&
        isMicOpened
      ) {
        changeVisualMode('close-mic');
        talk(e.result.text);
      }
    };
    speechRecognizer.canceled = (s, e) => {
      console.log(
        `Reconocimiento de voz cancelado: ErrorCode=${e.errorCode} Message=${e.errorDetails}`
      );
      if (e.reason === speechsdk.CancellationReason.Error) {
        changeVisualMode('reset');
      }
    };
    speechRecognizer.sessionStopped = (s, e) => {
      // estados a cambiar cuando el micro se pausa
      isMicOpened = false;
      setIsMicEnabled(false);
      console.log('Micrófono pausado.');
    };
  };

  /*
  const startOnceRecognition = (
    speechRecognizer: speechsdk.SpeechRecognizer
  ) => {
    // TODO: hay que adaptar este modo de escucha
    const onStartRecognition = (result: speechsdk.SpeechRecognitionResult) => {
      console.log('result', result, result.reason, result.text);
      if (result.reason === speechsdk.ResultReason.RecognizedSpeech) {
        // changeVisualMode('close-mic'); // adaptarlo a reconocimiento de voz no contínuo (por botón)
        addNewMessage(result.text, 'user', userName);
        talk(result.text);
      } else if (result.reason === speechsdk.ResultReason.Canceled) {
        changeVisualMode('reset');
        console.log(
          `"CANCELLED: ErrorCode=${result.reason} Message=${result.errorDetails}`
        );
      } else if (result.reason === speechsdk.ResultReason.NoMatch) {
        changeVisualMode('reset');
        setDisplayText('No se ha detectado la voz. Pulsa de nuevo para hablar');
      }
    };
    const onStartRecognitionError = (err: string) => {
      console.log('onStartRecognitionError', err);
      changeVisualMode('reset');
    };
    speechRecognizer.recognizeOnceAsync(
      onStartRecognition,
      onStartRecognitionError
    );
  }; */

  const getSpeechRecognizer = async (): Promise<speechsdk.SpeechRecognizer> => {
    const tokenObj = await getAzureVoiceToken();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage =
      initialConfig?.speechRecognitionLanguage ?? 'es-ES';
    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const speechRecognizer = new speechsdk.SpeechRecognizer(
      speechConfig,
      audioConfig
    );
    return speechRecognizer;
  };

  const enableMic = async () => {
    console.log('Creando el microfono...');
    !isReconnecting && setDisplayText('Activando micrófono...');
    const speechRecognizer = await getSpeechRecognizer();
    setRecognizerMethods(speechRecognizer);
    initializeOrResumeMic(speechRecognizer);
    // startOnceRecognition(speechRecognizer);
  };

  const sttFromMic = async (isManualClick: boolean = false) => {
    const isStreamingConnected = streamingState === 'connected';
    let isStreamingInitialized = isStreamingConnected;
    if ((streamingMode || streamingOnlyMode) && !isStreamingConnected) {
      !isReconnecting &&
        setDisplayText(
          `Iniciando conversación con ${initialConfig.avatarName || 'el asistente'
          }...`
        );
      isStreamingInitialized = await connect(0);
      !isStreamingInitialized && changeVisualMode('failed');
    }
    if (
      (isStreamingInitialized && isStreamingConnected) ||
      voiceOnlyMode ||
      voiceSpeechMode
    ) {
      if (isMicEnabled === false) {
        // abre el micro de forma manual
        isManualClick ? destroyMic(true) : changeVisualMode('open-mic');
      } else if (isMicEnabled) {
        // cierra el micro de forma manual
        isManualClick ? destroyMic(true) : changeVisualMode('close-mic');
      } else {
        // habilita el micro cuando vuelves a pulsar, si no se habilita desde el useEffect
        enableMic();
      }
    }
  };

  const onIceGatheringStateChange = (event: Event) => {
    // console.log('1', peerConnectionRef.current[indexConnection].connection?.iceGatheringState);
    // value "gathering" when d-id "/ice" is responding
    // value "complete" when d-id "/ice" has finished
  };

  async function onIceCandidate(event: RTCPeerConnectionIceEvent) {
    if (event.candidate) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

      if (!loadingIce) {
        try {
          loadingIce = true;
          await postOnIceCandidate(
            streamIdRef.current,
            sessionIdRef.current,
            candidate,
            sdpMid,
            sdpMLineIndex
          );
          loadingIce = false;
        } catch (error) {
          loadingIce = false;
          console.error('onIceCandidate catch', error);
          changeVisualMode('reset');
        }
      }
    }
  }

  const onIceConnectionStateChange = (event: Event) => {
    if (
      peerConnectionRef.current[indexConnection].connection
        ?.iceConnectionState === 'failed' ||
      peerConnectionRef.current[indexConnection].connection
        ?.iceConnectionState === 'closed'
    ) {
      console.log(
        'onIceConnectionStateChange (se cierra peerConnection):',
        peerConnectionRef.current[indexConnection].connection
          ?.iceConnectionState
      );
      stopAllStreams();
      closePC();
    }
  };

  const onConnectionStateChange = (event: Event) => {
    if (!peerConnectionRef.current[indexConnection].connection) return;
    console.log(
      `Connection State Change: ${peerConnectionRef.current[indexConnection].connection?.connectionState}`
    );
    setStreamingState((prevStreamingState) => {
      setPrevStreamingState(prevStreamingState);
      return peerConnectionRef.current[indexConnection].connection
        ?.connectionState;
    });
  };

  const onSignalingStateChange = (event: Event) => {
    if (!peerConnectionRef.current[indexConnection].connection) return;
    console.log(
      `Signaling State Change: ${peerConnectionRef.current[indexConnection].connection?.signalingState}`
    );
  };

  const onTrack = (event: RTCTrackEvent) => {
    if (!peerConnectionRef.current[indexConnection].connection) return;

    try {
      // Asegurarse de que el video aún no tiene un stream asignado
      if (talkVideoRef.current?.srcObject) return;

      // Asignar el stream al video
      if (talkVideoRef.current) {
        talkVideoRef.current.srcObject = event.streams[0];
      }
    } catch (e) {
      console.error('Error durante onTrack:', e);
    }
  };

  const stopAllStreams = async () => {
    if (talkVideoRef.current?.srcObject) {
      console.log('stopping video streams');
      (talkVideoRef.current.srcObject as MediaStream)
        .getTracks()
        .forEach((track) => track.stop());
      talkVideoRef.current.srcObject = null;
    }
  };

  const closePC = () => {
    // console.log("peerConnectionRef.current:", peerConnectionRef.current.signalingState);
    console.log('closePC invoked');
    const peerConnection = peerConnectionRef.current[indexConnection];
    if (peerConnection.connection) {
      console.log('stopping peer connection');
      peerConnection.connection.close();
      peerConnection.connection.removeEventListener(
        'icegatheringstatechange',
        onIceGatheringStateChange
      );
      peerConnection.connection.removeEventListener(
        'icecandidate',
        onIceCandidate
      );
      peerConnection.connection.removeEventListener(
        'iceconnectionstatechange',
        onIceConnectionStateChange
      );
      peerConnection.connection.removeEventListener(
        'connectionstatechange',
        onConnectionStateChange
      );
      peerConnection.connection.removeEventListener(
        'signalingstatechange',
        onSignalingStateChange
      );
      peerConnection.connection.removeEventListener('track', onTrack);
    }
    peerConnection.connection = null;
    peerConnection.active = false;
  };

  const createPeerConnection = async (
    offer: RTCSessionDescriptionInit,
    iceServers: RTCIceServer[],
    indexConnection: number
  ) => {
    try {
      if (!peerConnectionRef.current[indexConnection].connection) {
        console.log('Creando la conexión RTCPeerConnection...');
        peerConnectionRef.current[indexConnection].connection =
          new RTCPeerConnection({ iceServers });
        peerConnectionRef.current[indexConnection].active = true;
        const newPeerConnection =
          peerConnectionRef.current[indexConnection].connection;
        if (!newPeerConnection) {
          console.log('Algo está mal con la inicialización de la conexión');
          return;
        }
        newPeerConnection.addEventListener(
          'icegatheringstatechange',
          onIceGatheringStateChange,
          true
        );
        newPeerConnection.addEventListener(
          'icecandidate',
          onIceCandidate,
          true
        );
        newPeerConnection.addEventListener(
          'iceconnectionstatechange',
          onIceConnectionStateChange,
          true
        );
        newPeerConnection.addEventListener(
          'connectionstatechange',
          onConnectionStateChange,
          true
        );
        newPeerConnection.addEventListener(
          'signalingstatechange',
          onSignalingStateChange,
          true
        );
        newPeerConnection.addEventListener('track', onTrack, true);
        await newPeerConnection.setRemoteDescription(offer);
        const answer: RTCSessionDescriptionInit =
          await newPeerConnection.createAnswer();
        await newPeerConnection.setLocalDescription(answer);
        return answer;
      }
    } catch (e) {
      console.error('Error during createPeerConnection', e);
    }
  };

  const connect = async (indexConnection: number): Promise<boolean> => {
    console.log('Connect function invoked');
    if (
      peerConnectionRef.current[indexConnection].connection?.connectionState ===
      'connected'
    ) {
      console.log('Already connected, existing function');
      return true;
    }
    stopAllStreams();
    closePC();
    try {
      console.log('Inicializando una sesión de streaming...');
      const sessionData = await postCreateSession(initialConfig?.avatarImgUrl);
      if (sessionData) {
        const {
          id: newStreamId,
          offer,
          ice_servers: iceServers,
          session_id: newSessionId,
        } = sessionData;
        streamIdRef.current = newStreamId;
        sessionIdRef.current = newSessionId;
        const sessionClientAnswer: RTCSessionDescriptionInit | undefined =
          await createPeerConnection(offer, iceServers, indexConnection);
        if (sessionClientAnswer) {
          console.log('Mandar respuesta a D-ID');
          await postSendAnswer(
            streamIdRef.current,
            sessionIdRef.current,
            sessionClientAnswer
          );
        }
        return true;
      } else {
        console.log('No se pudo crear una sesión de streaming.');
        return false;
      }
    } catch (e) {
      console.error('Error during connection:', e);
      return false;
    }
  };

  const getSynthesizer = async (enableVolume: boolean) => {
    const tokenObj = await getAzureVoiceToken();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage =
      initialConfig?.speechRecognitionLanguage ?? 'es-ES';
    speechConfig.speechSynthesisLanguage =
      initialConfig?.speechRecognitionLanguage ?? 'es-ES';
    const player = new speechsdk.SpeakerAudioDestination();
    player.onAudioStart = function (s) {
      console.log('Comienza el speech');
      if (!enableVolume) {
        player.volume = 0;
        player.mute();
      }
    };
    player.onAudioEnd = function (s) {
      console.log('Termina el speech');
      changeVisualMode('finish');
    };
    const synthesizer = new speechsdk.SpeechSynthesizer(
      speechConfig,
      speechsdk.AudioConfig.fromSpeakerOutput(player)
    );
    return synthesizer;
  };

  const initializeSpeech = async ({
    speakText,
    timeoutTime,
    volume,
  }: AzureTalk) => {
    const synthesizer = await getSynthesizer(volume);
    const cb = (e: speechsdk.SpeechSynthesisResult) => {
      synthesizer.close();
    };
    const err = (e: string) => {
      synthesizer.close();
      console.log('synthesizer err');
    };
    setTimeout(() => {
      synthesizer.speakTextAsync(speakText, cb, err);
    }, timeoutTime);
  };

  const talk = async (
    userMessage: string,
    initialGreeting: boolean = false
  ) => {
    if (isWaitingResponse) {
      showToast('Se está procesando una respuesta, espera un momento.', 'warn');
      // isMicEnabled !== undefined && changeVisualMode('open-mic');
      return;
    }
    if (
      (streamingMode || streamingOnlyMode) &&
      (peerConnectionRef.current[indexConnection]?.connection
        ?.signalingState !== 'stable' ||
        peerConnectionRef.current[indexConnection]?.connection
          ?.iceConnectionState !== 'connected')
    ) {
      showToast('La conexión con el streaming no es estable.', 'warn');
      console.log('Talk - la conexión no es estable o no está conectada');
      return;
    }
    try {
      !initialGreeting &&
        addNewMessage(toMessageFormat(userMessage), 'user'); // No pasar el nombre del usuario
      isWaitingResponse = true;
      if (!streamingMode && !streamingOnlyMode) {
        setChatInput('');
        setIsChatSent(true);
      }
      setIsBotThinking(true);
      console.log('Recibiendo una respuesta del Avatar...');
      let chatText: string | undefined;
      let gptResponse: AvatarResponse | undefined;
      if (avatarType?.toLowerCase() === 'gptceu') {
        gptResponse = await getGptData(userMessage, userToken, user);
      } else {
        chatText = await getVariableData(userMessage, streamIdRef.current);
      }

      // Verificar si existe el campo respuesta_json
      if (gptResponse) {
        processGptResponse(gptResponse, setChartLabels, setChartData);
      }

      gptResponse = toMessageFormat(gptResponse || chatText);
      //console.log('Respuesta del Avatar:', gptResponse);
      const speakTalk: AzureTalk = {
        speakText: typeof gptResponse.respuesta_corta === 'string'
          ? gptResponse.respuesta_corta
          : gptResponse.respuesta_usuario ?? "",
        volume: VOLUMEN_ON,
        timeoutTime: 0,
      };
      isWaitingResponse = false;
      setIsBotThinking(false);
      addNewMessage(gptResponse, 'bot', initialConfig.avatarName || 'GPT-CEU'); // Pasar el nombre del bot
      if (streamingMode || streamingOnlyMode) {
        console.log('Intentando inicializar el streaming...');
        try {
          await postInitStreaming(
            streamIdRef.current,
            sessionIdRef.current,
            initialConfig?.azureVoiceId,
            speakTalk.speakText
          );
          speakTalk.volume = VOLUMEN_OFF;
          speakTalk.timeoutTime = 1000;
          speakTalk.speakText && initializeSpeech(speakTalk);
          console.log('Talk completed');
        } catch (error) {
          console.error('Fallo con el streaming:', error);
        }
      } else {
        setIsChatSent(false);
        voiceSpeechMode && speakTalk.speakText
          ? initializeSpeech(speakTalk)
          : changeVisualMode('finish');
      }
    } catch (e) {
      console.error('Fallo en Talk:', e);
    }
  };
  // #############################
  // Gestión del render del video y streaming

  const processGptResponse = (gptResponse: any, setChartLabels: Function, setChartData: Function) => {
    try {
      if (gptResponse.respuesta_corta) {
        let parsedJson;

        // Verificar si es un string (podría contener JSON)
        if (typeof gptResponse.respuesta_corta === 'string') {
          try {
            // Intentar parsear el string como JSON
            parsedJson = JSON.parse(gptResponse.respuesta_corta);
            console.log('respuesta_corta era un JSON string y se parseó a objeto:', parsedJson);
          } catch (e) {
            // Si no es un JSON válido, usar el string directamente
            parsedJson = gptResponse.respuesta_corta;
            console.log('respuesta_corta es un string normal:', parsedJson);
          }
        } else if (typeof gptResponse.respuesta_corta === 'object') {
          // Si ya es un objeto, usarlo directamente
          parsedJson = gptResponse.respuesta_corta;
          console.log('respuesta_corta ya era un objeto:', parsedJson);
        }

        // Procesar los datos para la gráfica
        if (Array.isArray(parsedJson)) {
          // Detectar dinámicamente las claves para etiquetas y valores
          const firstItem = parsedJson[0];
          if (firstItem) {
            const keys = Object.keys(firstItem);
            const labelKey = keys[0]; // Primera clave como etiquetas
            const valueKey = keys[1]; // Segunda clave como valores

            const filteredData = parsedJson.filter((item) => item[labelKey] !== null && item[labelKey] !== 'none' && item[valueKey] !== null && item[valueKey] !== 'none');

            const etiquetas = filteredData.map((item) => item[labelKey]);
            const valores = filteredData.map((item) => item[valueKey]);
            //console.log('Etiquetas detectadas:', etiquetas);
            //console.log('Valores detectados:', valores);

            setChartLabels(etiquetas); // Etiquetas para el eje X
            setChartData(valores); // Valores para las barras
          } else {
            console.log('El array está vacío o no tiene datos válidos.');
          }
        } else {
          console.log('El formato del JSON no es un array válido.');
        }
      } else {
        console.log('respuesta_corta no está presente o es inválida.');
      }
    } catch (error) {
      console.log('Error procesando respuesta_corta:', error);
    }
  };

  const handleVideoEnd = () => {
    // Cambiar a tu streaming en vivo aquí
    setShowStreaming(true);
    // Iniciar tu streaming y asignar el stream al elemento de video
    // Por ejemplo: talkVideoRef.current.srcObject = tuStream;
  };

  const addNewMessage = async (
    response: AvatarResponse,
    senderType: string,
    senderName: string = ''
  ) => {
    if (messages.length === 0) {
      setHasFirstMessageSent(true);
    }
    setMessages((prevMessages) => [
      ...prevMessages,
      { response, sender: senderType, author: senderName },
    ]);
    setDisplayText('Contestando...');
  };

  const setNewMessage = (
    prevMessage: AvatarResponse,
    newMessage: AvatarResponse
  ): AvatarResponse => {
    return {
      ...prevMessage,
      ...newMessage,
    };
  };

  const editMessageById = (id: string, newMessage: AvatarResponse | any) => {
    const messageToEdit = messages.find(
      (message) => message.response.id === id
    );
    if (messageToEdit) {
      messageToEdit.response = setNewMessage(
        messageToEdit.response,
        newMessage
      );
      setMessages(messages);
    }
  };

  const deleteMessageById = (id: string) => {
    const index = messages.findIndex((message) => message.response.id === id);
    messages.splice(index, 1);
    setMessages(structuredClone(messages));
  };

  useEffect(() => {
    switch (streamingState) {
      case 'connected':
        enableMic();
        break;
      case 'failed':
        if (prevStreamingState !== 'disconnected') {
          changeVisualMode('failed');
        }
        break;
      case 'disconnected':
        // changeVisualMode('reset'); // volver al estado inicial "Pulsa para hablar"
        // volver a conectar con el streaming
        console.log('---CONECTANDO DE NUEVO---');
        isReconnecting = true;
        destroyMic();
        sttFromMic();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamingState, prevStreamingState]);

  useEffect(() => {
    (async () => {
      if (isChatSent && chatInput && isMicEnabled === undefined) {
        await talk(chatInput);
      }
    })();
    if (!isChatSent) {
      isMicEnabled === undefined &&
        document.getElementById('chatInput')?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatSent, chatInput]);

  const onKeyDownChatInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (isBotThinking) {
        showToast('Espera a que el bot termine de escribir.', 'warn');
        return;
      }
      setIsChatSent(true);
    }
  };

  const onChangeChatInput = (e: React.FormEvent<HTMLInputElement>) =>
    setChatInput(e.currentTarget.value);

  const onClickChatInput = (e: React.MouseEvent<HTMLInputElement>) =>
    isMicEnabled !== undefined && destroyMic(true);

  useEffect(() => {
    if (streamingMode || streamingOnlyMode) {
      const talkingBtn = document.getElementById('talking-btn');
      const micControlText = document.getElementById('mic-control-btn');
      if (isMicEnabled === undefined) {
        talkingBtn.style.backgroundColor = 'rgba(50, 107, 240, 0.7)';
        micControlText &&
          (micControlText.textContent = 'Iniciar streaming y micro');
      } else if (isMicEnabled) {
        talkingBtn.style.backgroundColor = '#25a725';
        micControlText &&
          (micControlText.textContent = 'Deshabilitar micrófono');
      } else {
        talkingBtn.style.backgroundColor = '#e22121';
        micControlText && (micControlText.textContent = 'Habilitar micrófono');
      }
    }
    if (voiceOnlyMode || voiceSpeechMode) {
      if (isMicEnabled === undefined) {
        // mic no creado
        setMicImg(micNormalImg);
      } else if (isMicEnabled) {
        // mic creado
        setMicImg(micListeningImg);
      } else {
        // mic pausado
        setMicImg(micForbiddenImg);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicEnabled]);

  const uploadFilePartial = (file: File, description: string) =>
    uploadFile(file, user?.email, description);

  const context: AvatarContextModel = {
    addNewMessage,
    editMessageById,
    deleteMessageById,
    uploadFilePartial,
    talk,
    userName,
  };

  /*const resetChat = () => {
    window.location.reload();
  };*/

  useEffect(() => {
    if (chartLabels.length > 0 && chartData.length > 0) {
      setShowChart(true); // Mostrar la gráfica cuando haya datos
    } else {
      setShowChart(false); // Ocultar la gráfica si no hay datos
    }
  }, [chartLabels, chartData]);

  return (
    <AvatarContext.Provider value={context}>
      <NoData errorOnInitialConfig={errorOnInitialConfig}>
        <div id="parent-compontent" className="avatar-component">
          {/* Footer con imagen */}
          <footer className={`avatar-footer ${hasFirstMessageSent ? 'footer-normal' : 'footer-initial'}`}>
            <img alt="Footer Logo" className="footer-image" />
          </footer>
          {/* Mostrar la gráfica si hay datos */}
          {showChart && (
            <div className="chart-container">
              <BarChart labels={chartLabels} data={chartData} />
            </div>
          )}
          {(streamingMode || streamingOnlyMode) && (
            <div className={`subarea ${streamingOnlyMode ? 'subarea-img-full' : 'subarea-img'} ${showSuggestions ? 'move-up' : ''}`}>
              <div className="avatar-container">
                {showStreaming ? (
                  <>
                    <video
                      ref={talkVideoRef}
                      autoPlay
                      playsInline
                      poster={initialConfig?.avatarImgUrl}
                    />
                    <button
                      id="talking-btn"
                      className="subarea-img-button"
                      onClick={() => sttFromMic()}
                    >
                      {displayText}
                    </button>
                  </>
                ) : (
                  <ReactPlayer
                    url={initialConfig?.videoInicial}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100vh"
                    onEnded={handleVideoEnd}
                    config={{
                      vimeo: {
                        playerOptions: {
                          byline: false,
                          portrait: false,
                          title: false,
                          color: 'ffffff',
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {initialConfig && !streamingOnlyMode && (
            <div className={`subarea ${!streamingMode ? 'subarea-only-text' : 'subarea-text'} ${showSuggestions ? 'move-down' : ''}`}>              <div className="chat-container column">
              <div className={`chat-image-container ${hasFirstMessageSent ? 'chat-image-after-first-message' : ''}`}></div>
              <Chat messages={messages} isBotThinking={isBotThinking} />
              <div className={`chat-actions column ${hasFirstMessageSent ? 'chat-actions-after-first-message' : ''}`}>
                {!streamingMode && (
                  <>
                    <div className="chat-input-container">
                      <input
                        type="text"
                        id="chatInput"
                        name="chatInput"
                        className="chat-input"
                        value={chatInput}
                        onChange={onChangeChatInput}
                        onKeyDown={onKeyDownChatInput}
                        onClick={onClickChatInput}
                        autoComplete="off"
                        placeholder="Mensaje para tu asistente"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        className={`chat-icon ${!chatInput.trim() || isBotThinking ? 'disabled' : ''}`}
                        onClick={() => {
                          if (chatInput.trim() && !isBotThinking) {
                            setIsChatSent(true);
                          } else if (isBotThinking) {
                            showToast('Espera a que el bot termine de escribir.', 'warn');
                          }
                        }}
                      >
                        <path fill="currentColor" d="M3 20v-6l8-2l-8-2V4l19 8z" />
                      </svg>
                    </div>

                    {showSuggestions && (
                      <div className="suggestions-container" ref={suggestionsRef}>
                        {suggestions.map((frase, index) => (
                          <div
                            key={index}
                            className="suggestion-item"
                            onClick={() => {
                              setChatInput(frase);
                              setShowSuggestions(false);
                            }}>
                            {frase}
                          </div>
                        ))}
                      </div>
                    )}

                    {!hasFirstMessageSent && ( // Muestra los botones solo antes del primer mensaje
                      <div className="tag-container">
                        {tags.map((tag, index) => (
                          <button
                            key={index}
                            className="chat-tag"
                            onClick={() => {
                              const frasesDelTag = tagFrases[tag] || []; // Obtener las frases del tag
                              const fraseAleatoria = getRandomPhrase(frasesDelTag); // Seleccionar una frase aleatoria
                              setChatInput(fraseAleatoria); // Establecer la frase aleatoria como entrada del chat

                              // Mostrar sugerencias solo si hay más de una frase
                              if (frasesDelTag.length > 1) {
                                setSuggestions(frasesDelTag.filter(f => f !== fraseAleatoria)); // Mostrar las demás frases como sugerencias
                                setShowSuggestions(true); // Mostrar las sugerencias
                              } else {
                                setShowSuggestions(false); // Ocultar las sugerencias si solo hay una frase
                              }
                            }}>
                            {tag}
                          </button>
                        ))}
                      </div>
                    )}

                  </>
                )}
                {(voiceOnlyMode || voiceSpeechMode) && (
                  <button className="chat-mic-btn" onClick={() => sttFromMic(true)}>
                    <img src={micImg} alt="Mic" />
                  </button>
                )}
                {streamingMode && (
                  <button id="mic-control-btn" className="subarea-img-button" onClick={() => sttFromMic()}>
                    Habilitar micrófono
                  </button>
                )}
              </div>

            </div>
            </div>
          )}
          <LogoutBtn
            userToken={userToken}
            logout={logout}
            initials={userInitials}
            hasFirstMessageSent={hasFirstMessageSent}
            userName={userName}
            userRole={role} />
        </div>
      </NoData>
    </AvatarContext.Provider>
  );
}
export default AvatarApp;