import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarChartProps {
  labels: string[];
  data: number[];
}

const BarChart: React.FC<BarChartProps> = ({ labels, data }) => {
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Datos de respuesta',
        data,
        backgroundColor: "#27ae60",
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Gráfica de Respuesta',
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        beginAtZero: true, // Asegura que el eje Y comience en 0
        min: Math.min(...data) > 0 ? 0 : Math.min(...data), // Ajusta el mínimo dinámicamente
        max: Math.max(...data) + 5, // Ajusta el máximo dinámicamente con un margen
        ticks: {
          stepSize: Math.ceil((Math.max(...data) - Math.min(...data)) / 5), // Ajusta el tamaño de los pasos
        },
      },
      x: {
        type: 'category' as const,
        maxBarThickness: 20, // Ajusta el ancho del grupo de barras para evitar que se vean muy anchas
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChart;