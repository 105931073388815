import endpoints from './endpoints.json';
import { apiUrl, avatarType, usuario } from '../../constants';
import { AvatarConfiguration, AvatarResponse } from '../../models/avatar';
import { User } from '../../models/auth';
//import { toMessageFormat } from '../../models/chat';

// Función para obtener el avatar del usuario
const getUserAvatar = async (userToken: string, userEmail: string) => {
  try {
    // Log de la URL generada
    const avatarUrl = `${apiUrl}${endpoints.get.userAvatar}?email=${userEmail}`;
    console.log('URL generada para el avatar:', avatarUrl);  // Log para verificar la URL

    const response = await fetch(avatarUrl, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error al obtener avatar: ${response.status}`);
    }

    const data = await response.json();
    console.log('Datos del avatar obtenidos:', data);  // Log para verificar los datos de la respuesta
    return data.avatarUrl;  // Asegúrate de que la API devuelva la URL correcta
  } catch (error) {
    console.error('Error obteniendo avatar:', error);
    return null;
  }
};

const getGptData = async (
  userMessage: string,
  userToken: string,
  user: User
): Promise<AvatarResponse & { tags?: string[]; tagFrases?: { [key: string]: string[] } } | undefined> => {
  try {
    const response = await fetch(
      `${apiUrl}${endpoints.get.gpt}?code=${process.env.REACT_APP_GPT_CODE}&session_id=${user?.email}&mensaje=${userMessage}&usuario=${user?.name}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error al obtener datos: ${response.status}`);
    }

    const dataResponse: AvatarResponse = await response.json();
    //console.log('gpt response', dataResponse);

    // EXTRAER LOS TAGS
    const tags = dataResponse?.respuesta_corta?.tags?.map(tagObj => tagObj.Tag) || [];
    //console.log('Tags extraídos:', tags);

    // EXTRAER LAS FRASES DE CADA TAG
    const tagFrases = dataResponse?.respuesta_corta?.tags?.reduce((acc, tagObj) => {
      acc[tagObj.Tag] = tagObj.frases || [];
      return acc;
    }, {} as { [key: string]: string[] });

    //console.log('Frases extraídas:', tagFrases);

    // Devolver la respuesta original junto con los tags y frases
    return {
      ...dataResponse,
      tags,
      tagFrases,
    };
  } catch (error) {
    console.error('Error obteniendo datos GPT:', error);
  }
};


const getVariableData = async (
  userMessage: string,
  streamId: string
): Promise<string | undefined> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CEU_API}${endpoints.get.customData}?code=${process.env.REACT_APP_CONFIG_CODE}&session_id=${streamId}&mensaje=${userMessage}&usuario=${usuario}&avatar=${avatarType}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.text();
    return data;
  } catch (error) {
    console.log('variable data', error);
  }
};

const getInitialConfig = async (): Promise<AvatarConfiguration | undefined> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CEU_API}${endpoints.get.avatarConfiguration}?code=${process.env.REACT_APP_CONFIG_CODE}&avatar_type=${avatarType}`
    );
    if (response.ok && response.status === 200) {
      const jsonResponse: AvatarConfiguration = await response?.json();
      if (jsonResponse) {
        return jsonResponse;
      } else {
        console.error(`Configuración inicial sin datos`, jsonResponse);
      }
    }
  } catch (error) {
    console.error(`Error con el avatar ${avatarType}`, error);
  }
};

const uploadFile = async (
  file: File,
  userEmail: string,
  userMessage: string
) => {
  try {
    const postData = new FormData();
    postData.append('pdf', file);
    const response = await fetch(
      `${apiUrl}${endpoints.post.uploadFile}?code=${process.env.REACT_APP_UPLOAD_FILE_CODE}&user_mail=${userEmail}&mensaje=${userMessage}`,
      {
        method: 'POST',
        body: postData,
      }
    );
    if (response.ok && response.status === 200) {
      const data = await response?.json();
      if (data) {
        return data;
      }
    } else {
      console.error('Fallo al subir fichero');
      return false;
    }
  } catch (error) {
    console.error('Fallo al subir fichero -->', error);
    return false;
  }
};

export { getInitialConfig, getGptData, getVariableData, uploadFile, getUserAvatar };
