/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { scopes } from '../constants/auth';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { User } from '../models/auth';
import { avatarType } from '../constants';

const useMsalHook = () => {
  const { instance } = useMsal();
  const [userToken, setUserToken] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);

  const handleAccount = async () => {
    const redirectAccount = await instance.handleRedirectPromise();
    const activeAccount =
      redirectAccount?.account ?? instance.getActiveAccount();
    if (activeAccount) {
      instance.setActiveAccount(activeAccount);
      setAccessToken(activeAccount);
    } else {
      await instance.loginRedirect({ scopes });
    }
  };

  const setAccessToken = async (activeAccount: AccountInfo) => {
    let accessTokenResponse: AuthenticationResult | undefined;
    try {
      accessTokenResponse = await instance.acquireTokenSilent({
        account: activeAccount,
        scopes,
      });
    } catch (error: any) {
      console.log('get token catch ->', error.message);
      if (error?.message?.includes('login_required')) {
        await instance.loginRedirect({ scopes });
      }
    }
    if (accessTokenResponse) {
      const { accessToken, account } = accessTokenResponse;
      const { name, username } = account;
      setUser({ name, email: username, role: ''});
      setUserToken(accessToken);
    }
  };

  const logout = async () => {
    try {
      await instance.logoutRedirect();
    } catch (error) {
      console.error('error on logout', error);
    }
  };

  useEffect(() => {
    try {
      if (!userToken && avatarType?.toLowerCase() === 'gptceu') {
        handleAccount();
      }
    } catch (error: any) {
      console.log('catch error', error);
      if (error?.message.includes('popup_window_error')) {
        alert('Allow pop-ups to make login');
      }
    }
  }, [userToken]);

  return { user, userToken, logout };
};

export default useMsalHook;
